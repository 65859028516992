import React from "react";

import { Layout, SEO } from "../components";
import styled from "styled-components";
import { FormattedMessage } from "gatsby-plugin-intl";

const Wrapper = styled.section`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NotFoundPage = () => (
  <Layout>
    <SEO title="404_seo_title" />
    <Wrapper>
      <h1>
        <FormattedMessage id="404_title" />
      </h1>
    </Wrapper>
  </Layout>
);

export default NotFoundPage;
